<template>
	<button type="button" class="btn btn-link" v-on:click.prevent="deleteProductosGrupos()" :disabled="loadingTrash">
		<BtnLoading v-if="loadingTrash" :text="''" />
        <span v-else>
			<font-awesome-icon :icon="faTrash" />
        </span>

	</button>
</template>
<script type="text/javascript">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faTrash } from '@fortawesome/free-solid-svg-icons'
	import BtnLoading from '@/components/BtnLoading.vue'
	export default {
		props: ['codigo', 'url'],
		components: {
			FontAwesomeIcon,
			BtnLoading
		},
		data(){
			return {
				faTrash,
				loadingTrash: false
			}
		},
		methods: {
			deleteProductosGrupos(){
				var vm = this
				$.confirm({
					title: '¿Estás seguro que deseas eliminar el registro?',
					content: '¡Ya no podrás recuperar esta información!',
					theme: 'supervan', // 'material', 'bootstrap'
					buttons: {
						Confirmar: function () {
							vm.loadingTrash = true
							vm.$store.dispatch(vm.url, {id: vm.codigo})
							.then(response => {
								vm.$toast.info('Registro eliminado')
								return response
							}).catch(error =>{
								console.log(error)
								vm.$toast.error('Error al eliminar registro')
								return error
							}).then(() => {
								vm.loadingTrash = false
							})
						},
						Cancelar: function () {
						},
					}
				})
			},
		},
	}
</script>